import { css } from 'styled-components';
import { fontFamily, fontStyle } from '@naf/theme';

export default css`
  html {
    ${fontStyle.body};
    ${fontStyle.html};
    font-family: ${fontFamily.body};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-variant-numeric: lining-nums;
    font-feature-settings: 'lnum';
    /* vendor-prefixes */
    -moz-font-feature-settings: 'lnum=1';
    -ms-font-feature-settings: 'lnum';
    -webkit-font-feature-settings: 'lnum';
    -o-font-feature-settings: 'lnum';
  }
`;
