import styled, { css } from 'styled-components';
import React, { useState, useEffect } from 'react';
import { themeDefault, spacing, icons } from '@naf/theme';

interface StyleProps {
  first?: boolean;
  second?: boolean;
  third?: boolean;
  isOpen?: boolean;
}

interface Props {
  /**
   * Tekst for menyknappen.
   */
  label?: string;
  /**
   * Funksjon når knappen trykkes.
   */
  onClick: () => void;
  /**
   * Endrer menyknappen for å illustrere åpen/lukket state.
   */
  isOpen?: boolean;
  /**
   * Setter label som vil leses av skjermlesere dersom annen synlig label ikke er tilgjengelig.
   */
  ariaLabel?: string;
  className?: string;
}

export const MenuButton = ({
                             label,
                             onClick,
                             isOpen = false,
                             ariaLabel,
                             className
                           }: Props) => {
  const [mouseDown, setMouseDown] = useState(false);

  const removeOutline = () => {
    setMouseDown(true);
  };

  const addOutline = (e: KeyboardEvent) => {
    if (e.key === 'Tab') {
      setMouseDown(false);
    };
  };

  useEffect(() => {
    window.addEventListener('mousedown', removeOutline);

    return () => window.removeEventListener('mousedown', removeOutline);
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', addOutline);

    return () => window.removeEventListener('keydown', addOutline);
  }, []);

  return (
    <Btn className={className} id={mouseDown ? 'mousedown' : ''} onClick={() => onClick()} aria-label={ariaLabel}>
      {label && <Label>{label}</Label>}
      <Burger>
        <Line first isOpen={isOpen}>
          <Span />
        </Line>
        <Line second isOpen={isOpen} />
        <Line third isOpen={isOpen}>
          <Span />
        </Line>
      </Burger>
    </Btn>
  );
};

export default MenuButton;

const speedIn = '.15s';
const speedOut = '.15s';
const easeIn = 'ease';
const easeOut = 'ease';

const Btn = styled.button`
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  background: transparent;
  font: inherit;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  &#mousedown {
    outline: 0;
  }
`;

const Label = styled.span`
  display: block;
  margin-left: ${spacing.space8};
  color: ${({ theme }) =>
  theme.menuButton
    ? theme.menuButton.text
    : themeDefault.menuButton.text};
  line-height: 1;
`;

const Burger = styled.div`
  height: ${icons.l};
  padding: 0 ${spacing.space8};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

const Span = styled.span`
  width: 100%;
  height: 100%;
  display: block;
  transition: transform ${speedIn} ${easeIn};
  background: ${({ theme }) =>
  theme.menuButton
    ? theme.menuButton.icon
    : themeDefault.menuButton.icon};
`;

const Line = styled.div<StyleProps>`
      width: ${spacing.space20};
      height: 2px;
      transform-origin: center;
      background: transparent;

    /* first */
      ${({ first, isOpen }) =>
  first &&
  css`
          transform: translateY(-4px);
          transition: transform ${speedOut} ${speedIn} ${easeOut};

          ${isOpen === true &&
  css`
            transform: translateY(2px);
            transition: transform ${speedIn} ${easeIn};

            ${Span} {
              transform: rotate(45deg);
              transition: transform ${speedIn} ${speedIn} ${easeIn};
            }
          `}
        `}
    
    /* second */
      ${({ second, isOpen }) =>
  second &&
  css`
          transition: opacity 0s ${speedIn} ${easeIn};
          background: ${({ theme }) =>
    theme.menuButton
      ? theme.menuButton.icon
      : themeDefault.menuButton.icon};

          ${isOpen === true &&
  css`
            opacity: 0;
            transition: opacity 0s ${speedIn} ${easeIn};
          `}
        `}
    
    /* third */
      ${({ third, isOpen }) =>
  third &&
  css`
          transform: translateY(4px);
          transition: transform ${speedOut} ${speedIn} ${easeOut};

          ${isOpen === true &&
  css`
            transform: translateY(-2px);
            transition: transform ${speedIn} ${easeIn};

            ${Span} {
              transform: rotate(-45deg);
              transition: transform ${speedIn} ${speedIn} ${easeIn};
            }
          `}
        `}
    `;
