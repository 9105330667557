export const theme = {
  breakpoints: {
    s: '576px',
    m: '768px',
    l: '992px',
    xl: '1216px',
    xxl: '1920px',
  },
  icons: {
    s: '16px',
    m: '24px',
    l: '32px',
  },
  radius: {
    s: '2px',
    m: '4px',
    l: '6px',
  },
  spacing: {
    space4: '4px',
    space8: '8px',
    space12: '12px',
    space16: '16px',
    space20: '20px',
    space24: '24px',
    space32: '32px',
    space40: '40px',
    space48: '48px',
    space56: '56px',
    space64: '64px',
    space80: '80px',
    space120: '120px',
    space160: '160px',
  },
  fontFamily: {
    body: 'questa-sans, -system-ui, system-ui, sans-serif',
    heading: 'questa-sans, -system-ui, system-ui, sans-serif',
  },
  fontStyle: {
    html: {
      'font-size': '100%',
    },
    headers: {
      display: {
        'font-size': '64px',
        'line-height': '72px',
        'font-weight': '900',
        'letter-spacing': '0px',
        'font-style': 'normal',
      },
      header1: {
        'font-size': '48px',
        'line-height': '56px',
        'font-weight': '900',
        'letter-spacing': '0px',
        'font-style': 'normal',
      },
      header2: {
        'font-size': '28px',
        'line-height': '36px',
        'font-weight': '900',
        'letter-spacing': '0px',
        'font-style': 'normal',
      },
      header3: {
        'font-size': '22px',
        'line-height': '32px',
        'font-weight': '700',
        'letter-spacing': '0px',
        'font-style': 'normal',
      },
      subHeader: {
        'font-size': '22px',
        'line-height': '32px',
        'font-weight': '500',
        'letter-spacing': '0px',
        'font-style': 'normal',
      },
    },
    article: {
      body: {
        'font-size': '18px',
        'line-height': '28px',
        'font-weight': '300',
        'letter-spacing': '0px',
        'font-style': 'normal',
      },
      bodyHeader: {
        'font-size': '18px',
        'line-height': '28px',
        'font-weight': '700',
        'letter-spacing': '0px',
        'font-style': 'normal',
      },
      bodySubHeader: {
        'font-size': '18px',
        'line-height': '28px',
        'font-weight': '500',
        'letter-spacing': '0px',
        'font-style': 'normal',
      },
      bodyLarge: {
        'font-size': '20px',
        'line-height': '32px',
        'font-weight': '300',
        'letter-spacing': '0px',
        'font-style': 'normal',
      },
      caption: {
        'font-size': '14px',
        'line-height': '20px',
        'font-weight': '300',
        'letter-spacing': '0px',
        'font-style': 'italic',
      },
      captionHeader: {
        'font-size': '14px',
        'line-height': '20px',
        'font-weight': '700',
        'letter-spacing': '0px',
        'font-style': 'normal',
      },
      ingress: {
        'font-family': 'questa-slab, questa-sans, -system-ui, system-ui, sans-serif',
        'font-size': '26px',
        'line-height': '40px',
        'font-weight': '300',
        'letter-spacing': '0px',
        'font-style': 'italic',
      },
      blockquote: {
        'font-family': 'questa-slab, questa-sans, -system-ui, system-ui, sans-serif',
        'font-size': '28px',
        'line-height': '36px',
        'font-weight': '300',
        'letter-spacing': '0px',
        'font-style': 'italic',
      },
      blockquoteLarge: {
        'font-family': 'questa-slab, questa-sans, -system-ui, system-ui, sans-serif',
        'font-size': '36px',
        'line-height': '52px',
        'font-weight': '300',
        'letter-spacing': '0px',
        'font-style': 'italic',
      },
    },
    ui: {
      cardHeader: {
        'font-size': '24px',
        'line-height': '32px',
        'font-weight': '700',
        'letter-spacing': '0px',
        'font-style': 'normal',
      },
      details: {
        'font-size': '16px',
        'line-height': '24px',
        'font-weight': '300',
        'letter-spacing': '0px',
        'font-style': 'normal',
      },
      detailsHeader: {
        'font-size': '16px',
        'line-height': '24px',
        'font-weight': '700',
        'letter-spacing': '0px',
        'font-style': 'normal',
      },
      small: {
        'font-size': '14px',
        'line-height': '20px',
        'font-weight': '300',
        'letter-spacing': '0px',
        'font-style': 'normal',
      },
      tiny: {
        'font-size': '12px',
        'line-height': '16px',
        'font-weight': '400',
        'letter-spacing': '0px',
        'font-style': 'normal',
      },
      label: {
        'font-size': '14px',
        'line-height': '14px',
        'font-weight': '700',
        'letter-spacing': '0.04em',
        'font-style': 'normal',
        'text-transform': 'uppercase',
      },
      labelSmall: {
        'font-size': '12px',
        'line-height': '12px',
        'font-weight': '700',
        'letter-spacing': '0.04em',
        'font-style': 'normal',
        'text-transform': 'uppercase',
      },
      button: {
        'font-size': '18px',
        'line-height': '18px',
        'font-weight': '500',
        'letter-spacing': '0px',
        'font-style': 'normal',
      },
      buttonSmall: {
        'font-size': '16px',
        'line-height': '16px',
        'font-weight': '500',
        'letter-spacing': '0px',
        'font-style': 'normal',
      },
    },
    display: {
      'font-size': '64px',
      'line-height': '72px',
      'font-weight': '900',
      'letter-spacing': '0px',
      'font-style': 'normal',
    },
    header1: {
      'font-size': '48px',
      'line-height': '56px',
      'font-weight': '900',
      'letter-spacing': '0px',
      'font-style': 'normal',
    },
    header2: {
      'font-size': '28px',
      'line-height': '36px',
      'font-weight': '900',
      'letter-spacing': '0px',
      'font-style': 'normal',
    },
    header3: {
      'font-size': '22px',
      'line-height': '32px',
      'font-weight': '700',
      'letter-spacing': '0px',
      'font-style': 'normal',
    },
    header3Medium: {
      'font-size': '22px',
      'line-height': '32px',
      'font-weight': '500',
      'letter-spacing': '0px',
      'font-style': 'normal',
    },
    intro: {
      'font-family': 'questa-slab, questa-sans, -system-ui, system-ui, sans-serif',
      'font-size': '26px',
      'line-height': '40px',
      'font-weight': '300',
      'letter-spacing': '0px',
      'font-style': 'italic',
    },
    listTitle: {
      'font-size': '24px',
      'line-height': '32px',
      'font-weight': '700',
      'letter-spacing': '0px',
      'font-style': 'normal',
    },
    blockquote: {
      'font-family': 'questa-slab, questa-sans, -system-ui, system-ui, sans-serif',
      'line-height': '28px',
      'font-size': '36px',
      'font-weight': '300',
      'font-style': 'italic',
    },
    bodyLarge: {
      'font-size': '20px',
      'font-height': '32px',
      'font-weight': '300',
      'letter-spacing': '0px',
      'font-style': 'normal',
    },
    body: {
      'font-size': '18px',
      'line-height': '28px',
      'font-weight': '300',
      'letter-spacing': '0px',
      'font-style': 'normal',
    },
    bodyMedium: {
      'font-size': '18px',
      'line-height': '28px',
      'font-weight': '500',
      'letter-spacing': '0px',
      'font-style': 'normal',
    },
    bodyBold: {
      'font-size': '18px',
      'line-height': '28px',
      'font-weight': '700',
      'letter-spacing': '0px',
      'font-style': 'normal',
    },
    detailsHeader: {
      'font-size': '16px',
      'line-height': '24px',
      'font-weight': '700',
      'letter-spacing': '0px',
      'font-style': 'normal',
    },
    details: {
      'font-size': '16px',
      'line-height': '24px',
      'font-weight': '300',
      'letter-spacing': '0px',
      'font-style': 'normal',
    },
    caption: {
      'font-size': '14px',
      'line-height': '20px',
      'font-weight': '300',
      'letter-spacing': '0px',
      'font-style': 'italic',
    },
    captionTitle: {
      'font-size': '14px',
      'line-height': '20px',
      'font-weight': '700',
      'letter-spacing': '0px',
      'font-style': 'normal',
    },
    badge: {
      'font-size': '14px',
      'line-height': '14px',
      'font-weight': '700',
      'letter-spacing': '0.04em',
      'font-style': 'normal',
      'text-transform': 'uppercase',
    },
    badgeSmall: {
      'font-size': '12px',
      'line-height': '12px',
      'font-weight': '700',
      'letter-spacing': '0.04em',
      'font-style': 'normal',
      'text-transform': 'uppercase',
    },
    small: {
      'font-size': '14px',
      'line-height': '20px',
      'font-weight': '300',
      'letter-spacing': '0px',
      'font-style': 'normal',
    },
    tiny: {
      'font-size': '12px',
      'line-height': '16px',
      'font-weight': '400',
      'letter-spacing': '0px',
      'font-style': 'normal',
    },
    link: {
      color: '#1A1A1A',
      '&:visited': {
        color: '#1A1A1A',
      },
      '&:hover': {
        color: '#267C4F',
        'text-decoration': 'none',
      },
    },
  },
  mobile: {
    headers: {
      display: {
        'font-size': '40px',
        'line-height': '48px',
        'font-weight': '900',
        'letter-spacing': '0px',
        'font-style': 'normal',
      },
      header1: {
        'font-size': '32px',
        'line-height': '40px',
        'font-weight': '900',
        'letter-spacing': '0px',
        'font-style': 'normal',
      },
      header2: {
        'font-size': '24px',
        'line-height': '32px',
        'font-weight': '900',
        'letter-spacing': '0px',
        'font-style': 'normal',
      },
      header3: {
        'font-size': '20px',
        'line-height': '28px',
        'font-weight': '700',
        'letter-spacing': '0px',
        'font-style': 'normal',
      },
      subHeader: {
        'font-size': '20px',
        'line-height': '28px',
        'font-weight': '500',
        'letter-spacing': '0px',
        'font-style': 'normal',
      },
    },
    article: {
      ingress: {
        'font-family': 'questa-slab, questa-sans, -system-ui, system-ui, sans-serif',
        'font-size': '20px',
        'line-height': '32px',
        'font-weight': '300',
        'letter-spacing': '0px',
        'font-style': 'italic',
      },
      blockquote: {
        'font-family': 'questa-slab, questa-sans, -system-ui, system-ui, sans-serif',
        'font-size': '24px',
        'line-height': '32px',
        'font-weight': '300',
        'letter-spacing': '0px',
        'font-style': 'italic',
      },
      blockquoteLarge: {
        'font-family': 'questa-slab, questa-sans, -system-ui, system-ui, sans-serif',
        'font-size': '28px',
        'line-height': '42px',
        'font-weight': '300',
        'letter-spacing': '0px',
        'font-style': 'italic',
      },
    },
    ui: {
      cardHeader: {
        'font-size': '18px',
        'line-height': '24px',
        'font-weight': '700',
        'letter-spacing': '0px',
        'font-style': 'normal',
      },
    },
  },
};
