import { css } from 'styled-components';
import { fontStyle, themeDefault } from '@naf/theme';
import normalize from './global/normalize';
import typography from './global/typography';

const stylesheet = css`
  ${normalize}
  ${typography}

  html {
    overflow-x: hidden;
    background: ${({ theme }) =>
      theme.body ? theme.body.background : themeDefault.body.background};
    color: ${({ theme }) =>
      theme.body ? theme.body.text : themeDefault.body.text};
  }

  main:focus {
    outline: none;
  }

  body {
    min-height: 100%;
    position: relative;
    overflow-x: hidden;
    ${fontStyle.body};
  }

  a {
    ${fontStyle.link};
  }
`;

export { normalize, typography };
export default stylesheet;
