import { nafColor } from '@nafcore/theme';

const signature = nafColor.signature;
const primary = nafColor.primary;
// const secondary = nafColor.secondary;
const neutral = nafColor.neutral;
const information = nafColor.information;

export const themePackages = {
  body: {
    background: signature.white,
    text: signature.black,
  },
  accordion: {
    text: signature.white,
    background: signature.black,
    containerBorder: neutral.neutral3,
  },
  breadcrumbs: {
    currentPage: neutral.neutral4,
    link: {
      text: signature.black,
      icon: signature.black,
      hover: {
        text: primary.park,
        icon: primary.park,
      },
    },
  },
  button: {
    primary: {
      text: signature.black,
      background: signature.yellow,
      hover: {
        background: signature.yellow40,
      },
    },
    secondary: {
      text: primary.spruce,
      background: primary.moss,
      hover: {
        background: primary.dew,
      },
    },
    outline: {
      text: signature.black,
      background: 'transparent',
      border: neutral.neutral4,
      hover: {
        border: neutral.neutral3,
      },
    },
    dark: {
      text: primary.moss,
      background: primary.spruce,
      hover: {
        background: primary.moss,
        text: primary.spruce,
      },
    },
    disabled: {
      text: neutral.neutral4,
      background: neutral.neutral1,
    },
    loading: {
      text: signature.black,
      background: neutral.neutral1,
    },
  },
  buttonLink: {
    color: signature.black,
    hover: primary.park,
    border: {
      primary: primary.park,
      secondary: neutral.neutral4,
      negative: primary.kart,
    },
    negative: {
      color: signature.white,
      hover: primary.moss,
      disabled: neutral.neutral4,
    },
    disabled: neutral.neutral3,
  },
  cards: {
    color: signature.black,
    headerBorder: signature.yellow,
    outline: neutral.neutral3,
    chevron: primary.park,
    cardBorder: 'rgba(0, 0, 0, 0.12)',
    favoriteIcon: {
      outline: signature.black,
      filled: primary.park,
    },
  },
  cardGrid: {
    color: signature.black,
    headerBorder: signature.yellow,
    dateBorder: primary.kart,
  },
  checkbox: {
    default: {
      background: signature.white,
      border: neutral.neutral4,
      label: signature.black,
      icon: signature.black,
      message: signature.black,
      hover: {
        border: neutral.neutral3,
      },
      checked: {
        background: signature.yellow,
        border: signature.yellow,
      },
    },
    disabled: {
      background: signature.white,
      border: neutral.neutral3,
      label: neutral.neutral4,
      icon: neutral.neutral4,
      checked: {
        background: neutral.neutral3,
        border: neutral.neutral3,
      },
    },
    error: {
      background: signature.white,
      border: information.errorDark,
      icon: signature.white,
      message: information.errorDark,
      checked: {
        background: information.errorDark,
        border: information.errorDark,
      },
    },
  },
  chips: {
    color: signature.black,
    primary: {
      background: primary.kart,
      hover: {
        border: 'rgba(0, 0, 0, 0.4)',
      },
    },
    secondary: {
      background: primary.moss,
      hover: {
        border: 'rgba(0, 0, 0, 0.4)',
      },
    },
    signature: {
      background: signature.yellow,
      hover: {
        border: 'rgba(0, 0, 0, 0.4)',
      },
    },
    signature40: {
      background: signature.yellow40,
      hover: {
        border: 'rgba(0, 0, 0, 0.4)',
      },
    },
    outline: {
      background: 'transparent',
      border: neutral.neutral4,
      hover: {
        border: neutral.neutral3,
      },
    },
    disabled: {
      background: neutral.neutral1,
      color: neutral.neutral5,
    },
  },
  contextualFeedback: {
    background: {
      info: primary.dew,
      error: information.errorLight,
      neutral: neutral.neutral1,
      warning: information.warningLight,
      outline: 'transparent',
    },
    icon: {
      info: information.successDark,
      error: information.errorDark,
      neutral: neutral.neutral5,
      warning: information.warning,
      outline: neutral.neutral5,
    },
    border: neutral.neutral4,
  },
  dialog: {
    background: signature.white,
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)',
    },
  },
  dropdown: {
    background: signature.white,
    text: signature.black,
    hover: {
      background: signature.yellow10,
    },
    active: {
      background: signature.yellow40,
    },
  },
  flyoutModal: {
    background: signature.white,
    text: signature.black,
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)',
    },
  },
  iconButton: {
    background: neutral.neutral1,
    icon: signature.black,
    hover: neutral.neutral2,
    disabled: {
      background: '#F8F8F8',
      icon: neutral.neutral4,
    },
  },
  imageCarousel: {
    modal: {
      background: signature.white,
      hover: neutral.neutral6,
    },
    caption: {
      color: signature.white,
      background: 'rgba(26, 26, 26, 0.7)',
    },
    iconButton: {
      background: 'rgba(255, 255, 255, 0.72)',
      hover: signature.white,
    },
    dots: {
      background: neutral.neutral3,
      active: primary.park,
    },
  },
  imageModal: {
    background: signature.white,
    iconButton: {
      background: neutral.neutral1,
      icon: signature.black,
      mobile: 'rgba(255, 255, 255, 0.9)',
    },
    thumbnail: {
      background: neutral.neutral3,
    },
  },
  infoBox: {
    info: primary.dew,
    cta: signature.yellow20,
    outline: {
      background: signature.white,
      border: neutral.neutral3,
    },
  },
  label: {
    default: {
      background: signature.white,
      color: primary.spruce,
    },
    secondary: {
      background: primary.moss,
      color: primary.spruce,
    },
    dark: {
      background: primary.spruce,
      color: primary.moss,
    },
    signature: {
      background: signature.yellow,
      color: signature.black,
    },
  },
  loader: {
    primary: {
      spinner: primary.park,
      circle: primary.dew,
    },
    neutral: {
      spinner: signature.black,
      circle: neutral.neutral3,
    },
  },
  menuButton: {
    text: signature.black,
    icon: signature.black,
  },
  parts: {
    tooltip: {
      color: signature.white,
      icon: primary.spruce,
      background: primary.spruce,
    },
  },
  quote: {
    icon: primary.moss,
    text: signature.black,
    negative: signature.white,
    hover: primary.park,
  },
  radioButton: {
    outline: neutral.neutral3,
    default: {
      background: signature.white,
      border: neutral.neutral4,
      label: signature.black,
      icon: signature.black,
      message: neutral.neutral5,
      hover: {
        background: signature.white,
        border: neutral.neutral3,
      },
      checked: {
        border: signature.yellow,
      },
    },
    disabled: {
      background: signature.white,
      border: neutral.neutral3,
      label: neutral.neutral4,
      icon: neutral.neutral4,
      message: neutral.neutral4,
      checked: {
        border: neutral.neutral3,
      },
    },
    error: {
      background: signature.white,
      border: information.errorDark,
      label: signature.black,
      icon: information.errorLight,
      message: information.errorDark,
      checked: {
        border: information.errorDark,
      },
    },
  },
  search: {
    background: signature.white,
    border: neutral.neutral3,
    icon: neutral.neutral4,
    focus: {
      background: signature.yellow10,
      border: signature.black,
    },
  },
  shape: {},
  table: {
    border: {
      tHead: neutral.neutral3,
      td: neutral.neutral2,
    },
    hover: signature.yellow10,
  },
  tabs: {
    border: primary.kart,
    inactive: neutral.neutral5,
    active: signature.black,
  },
  tag: {},
  textField: {
    background: signature.white,
    border: neutral.neutral4,
    placeholder: neutral.neutral4,
    focus: {
      border: signature.black,
    },
    disabled: {
      background: neutral.neutral1,
      border: neutral.neutral4,
      color: neutral.neutral4,
    },
    error: information.errorDark,
    info: neutral.neutral5,
    message: information.errorDark,
    phoneBorder: signature.black,
    validation: {
      neutral: neutral.neutral3,
      weak: information.errorDark,
      medium: signature.yellow,
      strong: information.successDark,
    },
  },
  textList: {
    background: {
      bullet: primary.park,
      icon: primary.kart,
    },
    svg: primary.spruce,
  },
  toast: {
    information: primary.spruce,
    success: information.successDark,
    error: information.errorDark,
    text: signature.white,
  },
  toggle: {
    color: signature.black,
    checked: signature.yellow,
    unchecked: neutral.neutral3,
    switch: neutral.neutral5,
    disabled: {
      color: neutral.neutral3,
      background: neutral.neutral2,
    },
  },
};
