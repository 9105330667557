import { theme } from './theme.config';
import { themePackages } from './themePackages';

//theme values
export const fontStyle = theme.fontStyle;
export const mobile = theme.mobile;
export const fontFamily = theme.fontFamily;
export const breakpoints = theme.breakpoints;
export const icons = theme.icons;
export const radius = theme.radius;
export const spacing = theme.spacing;
export const themeDefault = themePackages;
